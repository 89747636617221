<template>
  <div>
    <b-modal
        id="permissions-modal"
        v-bind="$attrs"
        :ok-title="$i18n.t('Add')"
        ok-only
        size="lg"
        @ok="handleModalSubmit"
    >
      <template #modal-title>
        {{ $i18n.t('roles.form.add-new-category-access') }}
      </template>
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <form
            class="p-2"
            @submit.stop.prevent="handleSubmit(submitForm)"
        >

          <!-- Name -->
          <validation-provider
              #default="{errors}"
              name="name"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  :placeholder="$i18n.t('Name')"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <abilities-card
              ref="permissions"
              :modules="modules"
              :permissions="permissions"
              cols="6"
          />
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {required} from "@appCore/utils/validations/validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AbilitiesCard from "@/components/roles/abilities.vue";

export default {
  components: {
    AbilitiesCard,
  },
  props: ['abilities', 'repository'],
  data() {
    return {
      required,
      name: '',
      modules: {},
      permissions: {},
    }
  },
  created() {
    this.modules = this.abilities.modules
    this.permissions = this.abilities.permissions
  },
  methods: {
    handleModalSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitForm();
    },
    submitForm() {
      this.repository.store({
        name: this.name,
        abilities: this.$refs.permissions.addPermissionsToPayload(),
      }).then(response => {
        this.refresh()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      }).catch(error => {
        if (error.data && error.data.errors) {
          this.$refs.refFormObserver.setErrors(error.data.errors)
        }
      })
    },
    resetForm(abilities) {
      this.name = ''
      this.modules = abilities.modules
      this.permissions = abilities.permissions
    },
    refresh() {
      this.$emit('refresh');
      this.$bvModal.hide('permissions-modal')
    }
  }
}
</script>

<style scoped>

</style>
