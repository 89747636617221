<template>
  <b-card no-body>
    <loading :show="isLoadingPage">
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form
            class="mt-1 p-2 container"
            @submit.prevent="handleSubmit(submitForm)"
        >
          <b-row>
            <!-- Field: Name -->
            <b-col
                cols="12"
            >
              <validation-provider
                  #default="{errors}"
                  name="name"
                  rules="required"
              >
                <b-form-group
                    :label="$i18n.t('Role Name')"
                    label-for="role"
                >
                  <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false:null"
                      autofocus
                      :placeholder="$i18n.t('Name')"
                      trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- PERMISSIONS -->
          <validation-provider
              #default="{errors}"
              name="permissions"
              rules=""
          >
            <b-card
                class="border mt-1 p-3"
                no-body
            >
              <b-card-header class="p-0 mb-3">
                <b-card-title class="font-medium-2">
                  <b><span class="align-middle">{{ $i18n.t('roles.form.sections.abilities') }}</span></b>
                </b-card-title>
              </b-card-header>
              <abilities-card
                  ref="abilitiesRef"
                  :modules="abilities.modules"
                  :permissions="abilities.permissions"
              />
              <abilities-by-category
                  v-if="isAssociatedSection"
                  ref="associatedAbilitiesRef"
                  :abilities="associatedAbilities"
                  :accessibility="accessibility"
                  :is-loading.sync="isLoadingAssociated"
              />
            </b-card>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-row class="mt-2">
            <b-col>
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  type="submit"
                  variant="primary"
              >
                {{ isUpdateForm ? $i18n.t('Update') : $i18n.t('save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </loading>
  </b-card>
</template>

<script>
import {required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AbilitiesCard from '@/components/roles/abilities.vue'
import AbilitiesByCategory from '@/components/roles/abilities-by-category.vue'

export default {
  components: {
    AbilitiesCard,
    AbilitiesByCategory
  },
  emits: ['refresh-data'],
  props: {
    repository: {
      type: Object,
      required: true,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    isAssociatedSection: {
      type: Boolean,
      default: false,
    },
    roleName: {
      type: String,
      default: "",
    },
    abilities: {
      type: Object,
      required: true,
    },
    associatedAbilities: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    accessibility: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      required,
      name: '',
      isLoadingAssociated: false,
    }
  },
  computed: {
    isLoadingPage() {
      return this.isAssociatedSection ? this.isLoading || this.isLoadingAssociated : this.isLoading
    }
  },
  watch: {
    roleName(val) {
      this.name = val
    }
  },
  methods: {
    submitForm() {
      if (this.isUpdateForm) this.submitUpdateForm()
      else this.submitStoreForm()
    },
    submitStoreForm() {
      this.repository.store(this.formPayload()).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    submitUpdateForm() {
      this.repository.update(this.$route.params.id, this.formPayload()).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    formPayload() {
      const data = {
        name: this.name,
        abilities: {},
      }
      data.abilities = this.$refs.abilitiesRef.addPermissionsToPayload(data.abilities)
      if (this.isAssociatedSection) {
        const associatedAbilitiesRef = this.$refs.associatedAbilitiesRef
        const accessibilityData = associatedAbilitiesRef.getAccessibility()
        this.$set(data, 'accessibility', accessibilityData)
        if (!accessibilityData) {
          data.abilities = associatedAbilitiesRef.addPermissionsToPayload(data.abilities)
        }
      }
      return data
    },
    successCallback(response) {
      this.$emit('refresh-data')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
