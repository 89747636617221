<template>
  <div>
    <hr>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <b><span class="align-middle font-medium-2">{{ $i18n.t('roles.form.sections.associated') }}</span></b>

      <div>
        <b-form-checkbox
            v-model="checked"
            class="mt-1 ml-1"
            switch
        >
          {{ $i18n.t('roles.form.manage-permissions-by-category') }}
        </b-form-checkbox>

        <div
            v-if="checked && isCanDo('addAssociatedRole')"
            class="text-center mt-1"
        >
          <b-link
              id="show-btn"
              @click="openModal()"
          >
            <feather-icon
                icon="PlusIcon"
                size="18"
            />
            {{ $i18n.t('roles.form.add-new-category-access') }}
          </b-link>
          <add-associated-role-modal
              ref="add-associated-role-modal"
              :abilities="modalAbilities"
              :repository="associatedRoleRepo"
              @refresh="getAssociatedRoles"
          />
        </div>
      </div>
    </div>
    <div
        v-show="!checked"
        class="categoryPermission mt-1"
    >
      <abilities-card
          v-if="abilities"
          ref="permissions"
          :modules="abilities.modules"
          :permissions="abilities.permissions"
      />
    </div>
    <div
        v-show="checked"
        class="permissionByCategory"
    >
      <b-row
          v-for="category in categories"
          :key="category.id"
          class="mb-1"
      >
        <b-col cols="12">
          <b-row>
            <b-col
                cols="6"
                md="3"
            >
              <b>{{ category.name }}</b>
            </b-col>
            <b-col
                cols="6"
                md="4"
            >
              <v-select
                  v-model="selectedRoles[category.id]"
                  :options="associatedRoles"
                  :reduce="val => val.id"
                  class="d-inline-block w-100"
                  label="name"
                  :placeholder="$i18n.t('no_access')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbilitiesCard from '@/components/roles/abilities.vue'
import AddAssociatedRoleModal from '@/components/roles/modal.vue'
import RepositoryFactory from '@/repositories/RepositoryFactory';

export default {
  components: {
    AbilitiesCard,
    AddAssociatedRoleModal,
  },
  props: ['abilities', 'accessibility', 'isLoading'],
  data() {
    return {
      categories: [],
      associatedRoles: [],
      selectedRoles: [],
      checked: false,
      associatedRoleRepo: RepositoryFactory.create('associatedRoleRepo'),
      modalAbilities: {}
    }
  },
  watch: {
    accessibility(val) {
      val.forEach(element => {
        this.selectedRoles[element.category_id] = element.role_id
      })
      if (val && val.length > 0) this.checked = true
    },
  },
  async created() {
    this.$emit('update:is-loading', true)
    await this.getCategories()
    await this.getAssociatedRoles()
    if (this.isCanDo('addAssociatedRole')) {
      await this.getUtilsPermissions()
    }
    this.$emit('update:is-loading', false)
  },
  methods: {
    openModal() {
      this.$refs['add-associated-role-modal'].resetForm(this.modalAbilities)
      this.$bvModal.show('permissions-modal')
    },
    async getUtilsPermissions() {
      await this.utilsAppRepository().getPermissions()
          .then(res => {
            this.modalAbilities = res.data.data.associated
          })
    },
    async getCategories() {
      await this.utilsAppRepository().getCategories()
          .then(res => {
            this.categories = res.data.data
          })
    },
    async getAssociatedRoles() {
      await this.utilsAppRepository().getAssociatedRoles()
          .then(res => {
            this.associatedRoles = res.data.data
          })
    },
    addPermissionsToPayload(data) {
      return this.$refs.permissions.addPermissionsToPayload(data)
    },
    getAccessibility() {
      return this.checked ? this.formingSelectedRolesPayload() : null
    },
    formingSelectedRolesPayload() {
      const selectedRoles = []
      this.selectedRoles.forEach((val, key) => {
        if (val) {
          const item = {
            categoryId: key,
            accessRole: val
          }
          selectedRoles.push(item)
        }
      })
      return selectedRoles;
    },
  }
}
</script>
