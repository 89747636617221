<template>
  <b-row class="mb-1">
    <b-col
        v-for="(module,moduleKey) in modulesData"
        :key="moduleKey"
        class="mb-3"
        cols="12"
        md="4"
    >
      <b-card
          class="mb-0"
          no-body
      >
        <div class="pr-2">
          <h4>{{ module.name }}</h4>
          <b-form-checkbox
              v-for="(action,actionKey) in module.actions"
              :key="moduleKey+'.'+actionKey"
              v-model="modulesData[moduleKey]['actions'][actionKey]['value']"
              :checked="action.value"
              class="mt-1 ml-1"
          >
            {{ action.name }}
          </b-form-checkbox>
        </div>
      </b-card>
    </b-col>
    <b-col
        v-if="showPermissionsSection"
        cols="12"
    >
      <b-row>
        <b-col
            v-for="(permission,permissionKey) in permissionsData"
            :key="permissionKey"
            :cols="cols? cols : 4"
            class="pr-2"
        >
          <b-form-checkbox
              :key="permissionKey"
              v-model="permissionsData[permissionKey]['value']"
              :checked="permission.value"
              class="mt-1"
          >
            <h5>{{ permission.name }}</h5>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['modules', 'permissions', 'cols'],
  data() {
    return {
      modulesData: {},
      permissionsData: {},
    }
  },
  computed: {
    showPermissionsSection() {
      return (this.permissions && Object.keys(this.permissions).length > 0)
    }
  },
  watch: {
    modules(val) {
      this.modulesData = (typeof val === 'undefined') ? val : JSON.parse(JSON.stringify(val))
    },
    permissions(val) {
      this.permissionsData = (typeof val === 'undefined') ? val : JSON.parse(JSON.stringify(val))
    }
  },
  created() {
    this.modulesData = (typeof this.modules === 'undefined') ? this.modules : JSON.parse(JSON.stringify(this.modules))
    this.permissionsData = (typeof this.permissions === 'undefined') ? this.permissions : JSON.parse(JSON.stringify(this.permissions))
  },
  methods: {
    addPermissionsToPayload(data = {}) {
      const result = {};
      const permissions = this.fixPermissions(this.permissionsData)
      result.modules = {...data.modules, ...this.fixModulesPermissions(this.modulesData)}
      result.permissions = data.permissions ? data.permissions.concat(permissions) : permissions
      return result;
    },
    fixModulesPermissions(modules) {
      let moduleArr;
      const result = {};
      Object.keys(modules).forEach(moduleKey => {
        moduleArr = []
        const actions = modules[moduleKey].actions
        Object.keys(actions).forEach(actionKey => {
          if (actions[actionKey].value) {
            moduleArr.push(actionKey)
          }
        })
        if (moduleArr.length > 0) {
          result[moduleKey] = moduleArr
        }
      })
      return result
    },
    fixPermissions(permissions) {
      const result = [];
      Object.keys(permissions).forEach(permissionKey => {
        if (permissions[permissionKey].value) {
          result.push(permissionKey)
        }
      })
      return result;
    },
  }
}
</script>
